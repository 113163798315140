import React, {Fragment, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import InvoiceTable, {TableTypes} from '../../Components/InvoiceTable';
import InvoiceDialog, {InvoiceTypes} from '../../Dialogs/InvoiceDialog';
import {Input, Select, Row, Col, Spin, message} from 'antd';
import Button from '../../Widgets/Button';
import Block from '../../Widgets/Block';
import {ALL_SUPPORTED_RECEIPT_TYPE} from '../../Utils/InvoiceUtil';
import {INVOICE_STATUS, INVOICE_AFTER_TREATMENT_STATE} from '../../dictionary';
import {navigate} from 'gatsby';
import {useOutlet} from 'reconnect.js';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
const appConfig = require('../../data.json');

export const UISTATE = {
  NORMAL_LIST: 'NORMAL_LIST',
  MANUAL_LIST: 'MANUAL_LIST',
};

const PAGINATION_INIT = {
  current: 1,
  pageSize: 20,
};

export default function InvoiceList(props) {
  const {uiState} = props.pageContext;
  const [invoices, setInvoices] = useState([]);
  const [total, setTotal] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [filters, setFilters] = useState({
    search: '',
    category: '',
    invoice_status: '',
    after_threatment: '',
    ...PAGINATION_INIT,
  });
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = useOutlet('loading');

  const filterChange = useCallback((obj) =>
    setFilters((prev) => ({...prev, ...obj})),
  );

  const getInvoices = useCallback(async () => {
    let {
      current,
      pageSize,
      category,
      invoice_status,
      search,
      after_threatment,
    } = filters;
    setLoading(true);

    let params = {
      action_type: uiState === UISTATE.MANUAL_LIST ? 'MANUAL' : 'AUTO',
      offset: (current - 1) * pageSize,
      limit: pageSize,
      ordering: '-created',
      ...(search && {invoice_number: search}),
      ...(invoice_status && {invoice_status}),
      ...(after_threatment && {after_threatment}),
    };

    if (category) {
      if (category === 'two_copies') {
        params.category = 'B2C';
      } else if (category === 'three_copies') {
        params.category = 'B2B';
      } else if (category === 'donate') {
        params.carrier_type = 'donation';
      }
    }

    try {
      let resp = await actions.getInvoices(params);
      setInvoices(resp.results);
      setTotal(resp.count);
    } catch (ex) {
      message.warning('無法取得發票');
      console.warn(ex);
    }
    setLoading(false);
  }, [filters, uiState]);

  useEffect(() => {
    getInvoices();
  }, [getInvoices]);

  return (
    <Wrapper>
      <Block style={{marginBottom: 20}}>
        <div>
          <div className="row">
            <h4>種類</h4>
            <Select
              value={filters.category}
              onChange={(value) =>
                filterChange({
                  category: value,
                  ...PAGINATION_INIT,
                })
              }
              style={{marginRight: 40}}>
              <Select.Option value="" key="all">
                不限
              </Select.Option>

              {Object.values(ALL_SUPPORTED_RECEIPT_TYPE).map((x) => (
                <Select.Option value={x.value} key={x.idx}>
                  {x.label}
                </Select.Option>
              ))}
            </Select>
            <h4>狀態</h4>
            <Select
              value={filters.invoice_status}
              onChange={(value) =>
                filterChange({
                  invoice_status: value,
                  ...PAGINATION_INIT,
                })
              }
              style={{width: '100%'}}>
              <Select.Option value="" key="all">
                不限
              </Select.Option>

              {Object.keys(INVOICE_STATUS)
                .filter((x) => x !== 'voided')
                .map((x) => (
                  <Select.Option value={x} key={x}>
                    {INVOICE_STATUS[x]}
                  </Select.Option>
                ))}
            </Select>
          </div>
          <div className="row">
            <h4>處理狀態</h4>
            <Select
              value={filters.after_threatment}
              onChange={(value) =>
                filterChange({
                  after_threatment: value,
                  ...PAGINATION_INIT,
                })
              }
              style={{marginRight: 40}}>
              <Select.Option value="" key="all">
                不限
              </Select.Option>

              {Object.keys(INVOICE_AFTER_TREATMENT_STATE).map((x) => (
                <Select.Option value={x} key={x}>
                  {INVOICE_AFTER_TREATMENT_STATE[x]}
                </Select.Option>
              ))}
            </Select>
            <h4>搜尋發票號碼</h4>
            <Input.Search
              allowClear
              onSearch={(value) =>
                filterChange({
                  search: value,
                  ...PAGINATION_INIT,
                })
              }
            />
          </div>
        </div>
        <div className="divider" />
        <div className="row" style={{margin: 0}}>
          <div style={{flex: 1}} />
          {uiState === UISTATE.MANUAL_LIST && (
            <Button
              type="primary"
              style={{marginRight: 10}}
              onClick={() => setOpenDialog(true)}>
              自建空白發票
            </Button>
          )}
          <Button
            type="primary"
            onClick={getInvoices}
            loading={loading}
            icon={<Refresh color="#fff" size={20} style={{marginRight: 5}} />}>
            刷新
          </Button>
        </div>
      </Block>
      <InvoiceTable
        type={TableTypes.NORMAL}
        loading={loading}
        invoices={invoices}
        mounted={true}
        pagination={{
          pageSize: filters.pageSize,
          current: filters.current,
          total,
        }}
        onPaging={(pagination) =>
          filterChange({
            pageSize: pagination.pageSize,
            current: pagination.current,
          })
        }
      />
      {openDialog && (
        <InvoiceDialog
          type={InvoiceTypes.EMTPY_INVOICE}
          refresh={getInvoices}
          onClose={() => setOpenDialog(false)}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
