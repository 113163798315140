import React from 'react';
import * as Ant from 'antd';
import {getInvoiceCategory} from '../Utils/InvoiceUtil';
import {
  TAX_TYPE,
  INVOICE_STATUS,
  INVOICE_AFTER_TREATMENT_STATE,
} from '../dictionary';
import Anchor from '../Widgets/Anchor';

const TableTypes = {
  NORMAL: 0,
};

const InvoiceTable = ({
  invoices,
  loading,
  mounted = false,
  type = TableTypes.NORMAL,
  onPaging = () => {},
  pagination,
}) => {
  const columns = [
    {
      title: '發票號碼',
      render: (_, instance) => (
        <Anchor to={`/invoice/?id=${instance.id}`}>
          {instance.invoice_number
            ? `${instance.invoice_number} (#${instance.id})`
            : `--`}
        </Anchor>
      ),
      width: 150,
      fixed: 'left',
    },

    {
      title: '訂單編號',
      render: (_, instance) => (
        <Anchor to={`/order/?id=${instance.order}`}>
          {instance.display_id}
        </Anchor>
      ),
      width: 180,
    },
    // {
    //   title: "原訂單編號",
    //   render: instance => getTransId(instance.transaction_id),
    //   width: 150
    // },
    {
      title: '發票類型',
      render: (instance) => getInvoiceCategory(instance),
      width: 150,
    },
    {
      title: '買受人',
      render: (_, instance) =>
        (instance.buyer_name && instance.buyer_name) || '-',
      width: 100,
    },
    {
      title: '税別',
      render: (_, instance) => TAX_TYPE[instance.tax_type] || '-',
      width: 100,
    },
    {
      title: '總額',
      dataIndex: 'amount',
      width: 100,
    },
    {
      title: '狀態',
      render: (_, instance) => INVOICE_STATUS[instance.invoice_status] || '-',
      width: 100,
    },
    {
      title: '處理狀態',
      render: (_, instance) =>
        INVOICE_AFTER_TREATMENT_STATE[instance.after_threatment] || '-',
      width: 120,
    },
    {
      title: '開立日期',
      render: (_, instance) =>
        ` ${instance.created.slice(0, 10)} ${instance.created.slice(11, 16)}`,
      width: 200,
    },
  ];

  const extraProps = mounted
    ? {
        scroll: {
          x: window.innerWidth - 200 - 40,
        },
      }
    : {};

  return (
    <Ant.Table
      loading={loading}
      columns={columns}
      dataSource={invoices}
      rowKey={(item) => item.id}
      pagination={pagination}
      onChange={onPaging}
      {...extraProps}
    />
  );
};

export default InvoiceTable;

export {TableTypes};
