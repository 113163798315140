import React from 'react';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
const appConfig = require('../data.json');

export default function Hint({children, type, style}) {
  switch (type) {
    case 'info':
      return (
        <div
          style={{
            color: '#aaa',
            // display: 'flex',
            // alignItems: 'center',
            margin: '0 5px',
            ...style,
          }}>
          <InfoCircleOutlined
            style={{
              color: '#aaa',
              fontSize: 16,
              marginRight: 5,
              display: 'inline',
              verticalAlign: 'text-bottom',
            }}
          />
          {children}
        </div>
      );
    case 'warning':
    default:
      return (
        <div
          style={{
            color: appConfig.colors.error,
            // display: 'flex',
            // alignItems: 'center',
            margin: '0 5px',
            ...style,
          }}>
          <ExclamationCircleOutlined
            style={{
              color: appConfig.colors.error,
              fontSize: 16,
              marginRight: 5,
              display: 'inline',
              verticalAlign: 'text-bottom',
            }}
          />
          {children}
        </div>
      );
  }
}
